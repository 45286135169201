<template>
  <div>
    <div class="header-flex">
      <div>
        <div class="item">
          <span>订单id：</span>
          <el-input v-model="params.gid" placeholder="请输入订单号" />
        </div>
        <div class="item">
          <span>UID：</span>
          <el-input v-model="params.uid" placeholder="请输入UID" />
        </div>
        <div class="item">
          <span>拍卖ID：</span>
          <el-input v-model="params.auction_id" placeholder="请输入原站拍卖ID" />
        </div>
      </div>
      <div>
        <div class="item">
          <span>是否预约：</span>
          <el-select v-model="params.bid_time_type">
            <el-option value="" label="不限"></el-option>
            <el-option value="1" label="立即出价"></el-option>
            <el-option value="2" label="预约出价"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span>是否信用：</span>
          <el-select v-model="params.bid_pay_type">
            <el-option value="" label="不限"></el-option>
            <el-option value="1" label="全款出价"></el-option>
            <el-option value="2" label="信用出价"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span>是否即决：</span>
          <el-select v-model="params.purchase_type">
            <el-option value="" label="不限"></el-option>
            <el-option value="1" label="一口价"></el-option>
            <el-option value="2" label="竞价"></el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div class="item">
          <span>出价账号：</span>
          <el-select v-model="params.yahoo_account" filterable>
            <el-option v-for="item in userList" :key="item.id" :value="item.account" :label="item.account"></el-option>
          </el-select>
        </div>
        <div class="item">
          <span>状态：</span>
          <el-select v-model="params.auction_status	">
            <el-option value="0" label="待付款"></el-option>
            <el-option value="1" label="待出价"></el-option>
            <el-option value="2" label="出价中"></el-option>
            <el-option value="3" label="最高价"></el-option>
            <el-option value="4" label="被超过"></el-option>
            <el-option value="5" label="已得标"></el-option>
            <el-option value="6" label="竞拍失败"></el-option>
            <el-option value="1000" label="取消"></el-option>
          </el-select>
        </div>
        <div class="item">
          <el-button type="primary" @click="reset">重置</el-button>
          <el-button type="primary" @click="getList">查询</el-button>
        </div>
      </div>
    </div>
    <DragTable class="table" :header="header" :list-query="list">
      <template #id="{row}">
        <p>{{`订单id：${row.auction_order_id}`}}</p>
        <p>{{`用户昵称：${row.username}`}}</p>
        <p>{{`uid：${row.user_id}`}}</p>
      </template>
      <template #goods_title="{row}"><a :href="row.link" target="_blank">{{row.goods_title}}</a></template>
      <template #goods_image="{row}">
        <el-image 
          style="width: 100px; height: 100px"
          :src="row.goods_image" 
          :preview-src-list="[row.goods_image]">
        </el-image>
      </template>
      <template #info="{row}">
        <p>{{`当前价格：${row.auction_price}`}}</p>
        <p>{{`一口价：${row.auction_win_price}`}}</p>
        <p class="word-wrap">{{`结束时间（北京）：${$dayjs(row.auction_end_time*1000).format('YYYY-MM-DD HH:mm:ss')}`}}</p>
      </template>
      <template #func="{row}">
        <p>{{`用户出价：${row.last_price}`}}</p>
        <p>{{`是否即决：${row.purchase_type == 1 ? '一口价' : '竞价'}`}}</p>
        <p>{{`是否预约：${row.bid_time_type == 1 ? '立即出价' : '预约出价'}`}}</p>
        <p>{{`是否信用：${row.bid_pay_type == 1 ? '全款出价' : '信用出价'}`}}</p>
      </template>
      <template #auction_status="{row}">{{auction_status[row.auction_status]}}</template>
      <template #user="{row}">
        <p>{{`物流方式：${row.ship_way == 1 ? '优质' : '廉价'}`}}</p>
        <p>{{`备注：${row.remarks}`}}</p>
      </template>
      <template #ctl="{row}">
        <span class="word-color btn" @click="ctrl(row,1)">更新拍卖状态</span>
        <!-- <span class="word-color btn" @click="ctrl(row,2)">出价/操作记录</span> -->
        <span class="word-color red btn" @click="ctrl(row,3)">强制竞拍成功</span>
        <span class="word-color red btn" @click="ctrl(row,4)">强制竞拍失败</span>
      </template>
    </DragTable>
    <footer class="table-footer">
      <span></span>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total"
      ></el-pagination>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        id: '',
        page: 1,
        limit: 10,
        total: 0,
      },
      userList: [],
      auction_status: {
        0: '待付款',
        1: '待出价',
        2: '出价中',
        3: '最高价',
        4: '被超过',
        5: '已得标',
        6: '竞拍失败',
        1000: '取消'
      },
      header: [
        { name: 'ID', slot: 'id' },
        { name: '商品名', slot: 'goods_title' },
        { name: '商品图片', slot: 'goods_image' },
        { name: '原站信息', slot: 'info' },
        { name: '出价方式', slot: 'func' },
        { name: '状态', slot: 'auction_status' },
        { name: '用户偏好', slot: 'user' },
        { name: '操作', slot: 'ctl' },
      ],
      list: {
        data: []
      }
    }
  },
  mounted() {
    this.getList()
    this.getUser()
  },
  methods: {
    getList() {
      this.$axios.get(`/marketCenter/yahoo/order/list`,{
        params: this.params
      })
      .then(res=>{
        this.list.data = res.data.data
        this.params.total = res.data.total
      })
      
    },
    getUser() {
      this.$axios.get(`/marketCenter/yahoo/account/list`)
      .then(res=>{
        this.userList = res.data
      })
    },
    reset() {
      for(let key in this.params) {
        if(key == 'page') continue
        this.params[key] = undefined
      }
      this.params.page = 1
      this.params.limit = 10
      this.getList()
    },
    async ctrl(data,type) {
      if(type == 1) {
        this.$axios.post(`/marketCenter/yahoo/order/auction/updateInfo`, {id:data.auction_order_id})
        .then(res=>{
          this.$message.success(res.msg)
          this.getList()
        })
        
      }
      if(type == 2) {
        this.$router.push(`/goods_order_manager/orderManager/yahoo_order_record?id=${data.auction_order_id}`)
      }
      if(type == 3) {
        const confirm = await this.$goDialog('是否确认强制成功？')
        if(!confirm) return
        this.$axios.post(`/marketCenter/yahoo/order/auction/forceSuccess`, {id:data.auction_order_id})
        .then(res=>{
          this.$message.success(res.msg)
          this.getList()
        })
        
      }
      if(type == 4) {
        const confirm = await this.$goDialog('是否确认强制失败？')
        if(!confirm) return
        this.$axios.post(`/marketCenter/yahoo/order/auction/forceFail`, {id:data.auction_order_id})
        .then(res=>{
          this.$message.success(res.msg)
          this.getList()
        })
        
      }
    },
    handleSizeChange(val) {
      this.params.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.params.page = val
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.header-flex{
  display: block;
  div{
    display: flex;
    .item{
      margin-right: 20px;
      width: 270px;
      &>span{
        display: block;
        width: 80px;
        flex-shrink: 0;
      }
      &>div{
        width: 100%;
      }
    }
  }
}
.table{
  img{
    width: 200px;
    height: 200px;
  }
  p{
    text-align: left;
  }
}
.btn{
  display: block;
}
</style>